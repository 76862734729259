import { handleErrors } from "lib/handleErrors";
import { getCurrentLocale } from "lib/locale";

const apiUrl = "/api_hardware/zip";

const request = (fetchUrl: string, method: string, body?: any) => {
  const url = `${apiUrl}${fetchUrl}`;

  const locale = getCurrentLocale();

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": locale,
      accept: "application/json",
    },
    body,
  };

  return fetch(url, options);
};

const getState = async () => {
  const resp = await request("/state", "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const testEmeter = async () => {
  const resp = await request("/test", "PUT");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

const relayEnable = async () => {
  const resp = await request("/enable", "POST");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

const relayDisable = async () => {
  const resp = await request("/disable", "POST");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

const relayAuto = async () => {
  const resp = await request("/enable", "POST");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

const relayManual = async () => {
  const resp = await request("/disable", "POST");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// https://button.office.tiram.icu/
const flashSmartButton = async (group: number) => {
  const url = `/api/flash/${group}`;

  const locale = getCurrentLocale();

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": locale,
      accept: "application/json",
      cors: "no-cors",
    },
  };

  const resp = await fetch(url, options);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

export const service = {
  getState,
  testEmeter,
  relayEnable,
  relayDisable,
  relayAuto,
  relayManual,
  flashSmartButton,
};
